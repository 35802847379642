.container-home {
    margin: 0 auto;
    background: 
      conic-gradient(from 90deg at 2px 2px, #CBCDF6 90deg, transparent 0) -2px -2px / 35px 35px;
  }

.container-home-ssaem {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #FCEAE6 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-staff-pengajar {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #FCEAE6 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-medsos {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #EBEDFB 90deg, transparent 0) -2px -2px / 50px 50px;
}

.container-toktok-class {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #EBEDFB 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-buku-borassaem {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #EBEDFB 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-testimoni {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #FCEAE6 90deg, transparent 0) -2px -2px / 40px 40px;
}

.container-jenis-kelas-desktop {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #EBEDFB 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-jenis-kelas-mobile {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #FCEAE6 90deg, transparent 0) -2px -2px / 35px 35px;
}

.container-tingkatan-kelas {
  margin: 0 auto;
  background: 
    conic-gradient(from 90deg at 2px 2px, #EBEDFB 90deg, transparent 0) -2px -2px / 35px 35px;
}


/* Track */
.scrollbar-track-bg::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
.scrollbar-thumb-bg::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scrollbar-thumb-bg::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* text animation effect */
.animation {
  height: 70px;
  overflow: hidden;
  margin-left: 1rem;
  margin-top: -0.5rem;
}

.animation > div > div {
  padding: 0.25rem 0.75rem;
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

@keyframes text-animation {
  0%, 10% {
    margin-top: 0;
  }
  20%, 30% {
    margin-top: -5.62rem;
  }
  40%, 60% {
    margin-top: -11.24rem;
  }
  70%, 80% {
    margin-top: -5.62rem;
  }
  90%, 100% {
    margin-top: 0;
  }
}



