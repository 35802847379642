.transition-fade {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.cross-fade {
    opacity: 0.4;
    pointer-events: none;
    transition: opacity 1.2s ease-in-out;
}



/* efek buka buku */

.flip-container {
    perspective: 1000px;
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height: 100%;
  }
  
  .hovered .flipper {
    transform: rotateY(180deg);
  }
  
  .front,
  .back {
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    
  }
  
  .back {
    transform: rotateY(180deg);
    /* background-color: #f5f5f5; */
  }

  /* background-color: #727AE3 */